import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { StyledContainer, Product, ProductContent } from "./styles"

SwiperCore.use([Navigation, Pagination])

const Bancadas = () => {
  const data = useStaticQuery(graphql`
    query {
      bancada: file(relativePath: { eq: "products/bancada.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bancada2: file(relativePath: { eq: "products/bancada2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bancada3: file(relativePath: { eq: "products/bancada3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bancada4: file(relativePath: { eq: "products/bancada4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledContainer as="section">
      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.bancada.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.bancada2.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.bancada3.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.bancada4.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Bancada de aço</h3>

          <ul>
            <li>Com reforço</li>
            <li>Altura: 93 cm </li>
            <li>Largura: 193 cm</li>
            <li>Profundidade: 50 cm</li>
          </ul>

          {/* <p className="item-price">
            R$ 618,<span>00</span>
          </p>

          <p className="item-parcel">
            em até 10x sem juros no cartão de crédito
          </p> */}
        </ProductContent>
      </Product>
    </StyledContainer>
  )
}

export default Bancadas
