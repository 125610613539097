import React from "react"

import SEO from "../components/seo"

import Bancadas from "../components/products/armarios-e-bancadas"

const ArmarioEBancadas = () => (
  <>
    <SEO title="Armários e Bancadas" />

    <Bancadas />
  </>
)

export default ArmarioEBancadas
